<template>

  <uploader ref="uploader"
            @file-added="onFileAdded"
            @file-success="onFileSuccess"
            :options="options">
    <uploader-btn :attrs="{ accept: array[accept] }">
      <div class="uploader-text" v-html="html"/>
    </uploader-btn>
  </uploader>

</template>
<style>
</style>
<script type="text/babel">
import SparkMD5 from 'spark-md5'
import Vue from 'vue'
import uploader from 'vue-simple-uploader'

Vue.use(uploader)

function checkChunkUploadedByResponse(chunk, message) {
  let objMessage = JSON.parse(message)
  if (objMessage.data.done == 1) {
    return true
  }
  return false
}

export default {
  name:       'myUploader',
  components: {},
  props:      {
    html:   {
      type:    String,
      default: '上传',
    },
    accept: {
      type:    String,
      default: 'image',
    },
  },
  data() {
    return {
      options: {
        target:                '/api/index/upload',
        testChunks:            true,
        chunkSize:             2 * 1024 * 1024,
        simultaneousUploads:   1,
        allowDuplicateUploads: true,
        singleFile:            true,
        checkChunkUploadedByResponse,
      },
      array:   {
        rar:   '.rar,.zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed',
        image: 'image/*',
        word:  '.csv,.xlsx,.xls,.csv,.doc,.docx,.ppt,.pptx',
        video: 'video/mp4,video/x-m4v,video/*',
      },
    }
  },
  mounted() {

  },
  methods: {

    onFileAdded(file) {
      // 计算MD5，下文会提到
      this.$layer.loading()
      this.computeMD5(file)
    },

    onFileSuccess(rootFile, file, response, chunk) {
      this.$layer.closeAll()
      response = JSON.parse(response)
      this.$emit('done', response)
    },
    computeMD5(file) {
      let fileReader   = new FileReader()
      let blobSlice    = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      let currentChunk = 0
      const chunkSize  = 2 * 1024 * 1024
      let chunks       = Math.ceil(file.size / chunkSize)
      let spark        = new SparkMD5.ArrayBuffer()
      file.pause()
      loadNext()
      fileReader.onload  = (e => {
        spark.append(e.target.result)
        if (currentChunk < chunks) {
          currentChunk++
          loadNext()
        } else {
          file.uniqueIdentifier = spark.end()
          file.resume()
        }
      })
      fileReader.onerror = function () {
        file.cancel()
      }

      function loadNext() {
        let start = currentChunk * chunkSize
        let end   = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
        fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end))
      }
    },

  },
}
</script>

<style lang="less" scoped>

.uploader-btn {
  border: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.uploader-btn:hover {
  background-color: unset !important;
}
</style>
