<template>

  <!--  <div id="app" class="" style="width:10rem;margin:0 auto;overflow-x:hidden; position:relative;min-height: 100vh">-->
  <div id="app">
    <!--    <script src="/public/main/js/flexible.js"></script>-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive"/>
  </div>

</template>
<style>
a:hover {
  text-decoration: none
}

html {
  background: none
}
</style>
<script type="text/babel">


import {isMobile} from '../utils/util'

export default {
  components: {},
  mounted() {

    if (isMobile()) {
      import ('../../../assets/main/js/global.js')

      import ('../../../assets/main/css/global.css')
      import ('../../../assets/main/css/style.css')
    } else {
      // import ('../../../assets/main/js/mediaelement-and-player.min.js')
      import ('../../../assets/wap/css/global.css')
      import ('../../../assets/wap/css/style.css')

    }
  },
  created() {

  },

}
</script>
