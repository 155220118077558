import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueCookies from 'vue-cookies'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales  = require.context(
        '../../../config/locale',
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i,
    )
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)

        if (matched && matched.length > 1) {
            const locale     = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

function getLanguage() {
    if (VueCookies.get('locale')) {
        return VueCookies.get('locale')
    }
    return 'zh'
}

export default function createI18n() {

    return new VueI18n({
        locale:   getLanguage(),
        messages: loadLocaleMessages(),
    })
}
