import {ACCESS_TOKEN_USER} from '../store/mutation-types'

const loginRoutePath = '/login'
import router from '../router'
import store from '../store/'
import Vue from 'vue'
import storage from 'store'

router.beforeEach(async (route, redirect, next) => {

    if (store.state.web.web_config.webname == undefined) {
        let results = await Vue.prototype.$request('/api/config_/', {data: {}})

        if (!localStorage.getItem('uuid')) {
            localStorage.setItem('uuid', results.data.uuid)
        }

        store.commit('web/setWebConfig', results.data)

    }

    if (route.fullPath.startsWith('/user')) {

        if (storage.get(ACCESS_TOKEN_USER)) {

            let redirect_ = decodeURIComponent(redirect.query.redirect || route.path)

            redirect_ = redirect_.split('?')[0]

            if (route.path === redirect_) {
                next()
            } else {
                // 跳转到目的路由
                next({path: redirect_})
            }

        } else {

            if (route.path == loginRoutePath) {
                next()
            } else {
                next({path: loginRoutePath, query: {redirect: route.fullPath}})
            }
        }

    } else {
        next()
    }

})
