import Vue from 'vue'
import Router from 'vue-router'

// hack router push callback
const originalPush    = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

let dir = 'web'

if (navigator.userAgent.match(/(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i)) {
    dir = 'page'
}


export default new Router({
    mode: 'history',
    base: '/',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) { //如果savedPosition存在，滚动条会自动跳到记录的值的地方
            return savedPosition
        } else {
            return {x: 0, y: 0}//savedPosition也是一个记录x轴和y轴位置的对象
        }
    },

    routes: [
        {path: '/', component: () => import('../' + dir + '/index'), meta: {keepAlive: true}},
        {
            path:      '/login',
            name:      'login',
            component: () => import('../' + dir + '/login.vue'),
            meta:      {
                title: 'Login',
            },
        },
        {
            path:      '/register',
            name:      'register',
            component: () => import('../' + dir + '/register.vue'),

        },
        {
            path:      '/wjmm',
            name:      'wjmm',
            component: () => import('../' + dir + '/wjmm.vue'),
            meta:      {
                title: '忘记密码',
            },
        },
        {
            path:      '/all',
            name:      'All',
            component: () => import('../' + dir + '/all.vue'),
        },
        {
            path:      '/allxq',
            name:      'Allxq',
            component: () => import('../' + dir + '/allxq.vue'),
        },
        {
            path:      '/review',
            name:      'Review',
            component: () => import('../' + dir + '/review.vue'),
        },
        {
            path:      '/sold',
            name:      'Sold',
            component: () => import('../' + dir + '/sold.vue'),
        },
        
        {
            path:      '/reviewxq',
            name:      'Reviewxq',
            component: () => import('../' + dir + '/reviewxq.vue'),
        },
        {
            path:      '/soldxq',
            component: () => import('../' + dir + '/soldxq.vue'),
        },
        {
            path:      '/reviewxq2',
            name:      'Reviewxq2',
            component: () => import('../' + dir + '/reviewxq2.vue'),
        },
        {
            path:      '/faq',
            name:      'faq',
            component: () => import('../' + dir + '/faq.vue'),
        },
        {
            path:      '/guide',
            name:      'guide',
            component: () => import('../' + dir + '/guide.vue'),

        },
        {
            path:      '/contact',
            name:      'contact',
            component: () => import('../' + dir + '/contact.vue'),

        },
        {
            path:      '/order',
            component: () => import('../' + dir + '/order.vue'),

        },
        {
            path:      '/user/wdsc',
            name:      'wdsc',
            component: () => import('../' + dir + '/wdsc.vue'),

        },
        {
            path:      '/user/shxq',
            name:      'shxq',
            component: () => import('../' + dir + '/shxq.vue'),

        },
        {
            path:      '/tjdd',
            component: () => import('../' + dir + '/tjdd.vue'),

        },
        {
            path:      '/user/shth',
            name:      'shth',
            component: () => import('../' + dir + '/shth.vue'),

        },
        {
            path:      '/user/shthxq',
            component: () => import('../' + dir + '/shthxq.vue'),

        },
        {
            path:      '/user/address',
            name:      'address',
            component: () => import('../' + dir + '/address.vue'),

        },
        {
            path:      '/user/sqsh',
            name:      'sqsh',
            component: () => import('../' + dir + '/sqsh.vue'),

        },
        {
            path:      '/user/ddxx',
            name:      'ddxx',
            component: () => import('../' + dir + '/ddxx.vue'),

        },
        {
            path:      '/cart',
            name:      'cart',
            component: () => import('../' + dir + '/cart.vue'),

        },
        {
            path:      '/user/wddd',
            component: () => import('../' + dir + '/wddd.vue'),

        },
        {
            path:      '/user/grzl',
            name:      'grzl',
            component: () => import('../' + dir + '/grzl.vue'),

        },
    ],
    // scrollBehavior(to, from, savedPosition) {  
    //     const position = sessionStorage.getItem(to.path); 
    //     if (position) {  
    //         return JSON.parse(position);  
    //         console.log('router sposition',position)
    //       } else if (savedPosition) {  
    //         // 如果没有找到保存的位置，但浏览器提供了之前的位置，则使用它  
    //         return savedPosition;  
    //         console.log('router savedPosition',savedPosition)
    //       } else {  
    //         // 否则，返回到页面的顶部  
    //         return { x: 0, y: 0 };  
    //         console.log('router',0)
    //       }  
    //   }  
})
