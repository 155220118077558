import { render, staticRenderFns } from "./myUploader.vue?vue&type=template&id=16a0f28c&scoped=true"
import script from "./myUploader.vue?vue&type=script&lang=js"
export * from "./myUploader.vue?vue&type=script&lang=js"
import style1 from "./myUploader.vue?vue&type=style&index=1&id=16a0f28c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a0f28c",
  null
  
)

export default component.exports