var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > _vm.display
    ? _c(
        "nav",
        [
          _vm.current > 1
            ? _c(
                "a",
                {
                  staticClass: "lr",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrent(1)
                    },
                  },
                },
                [_vm._v(" Home ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.current > 1
            ? _c(
                "a",
                {
                  staticClass: "lr",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrent(_vm.current - 1)
                    },
                  },
                },
                [_vm._v("\n    Perv\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.grouplist, function (p, key) {
            return _c(
              "a",
              {
                key: key,
                class: { now: _vm.current == p.val },
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.setCurrent(p.val)
                  },
                },
              },
              [_vm._v("\n    " + _vm._s(p.text) + "\n  ")]
            )
          }),
          _vm._v(" "),
          _vm.current < _vm.page
            ? _c(
                "a",
                {
                  staticClass: "lr",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrent(_vm.current + 1)
                    },
                  },
                },
                [_vm._v("\n    Next\n  ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.current < _vm.page
            ? _c(
                "a",
                {
                  staticClass: "lr",
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function ($event) {
                      return _vm.setCurrent(_vm.page)
                    },
                  },
                },
                [_vm._v(" End ")]
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }