var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "uploader",
    {
      ref: "uploader",
      attrs: { options: _vm.options },
      on: { "file-added": _vm.onFileAdded, "file-success": _vm.onFileSuccess },
    },
    [
      _c(
        "uploader-btn",
        { attrs: { attrs: { accept: _vm.array[_vm.accept] } } },
        [
          _c("div", {
            staticClass: "uploader-text",
            domProps: { innerHTML: _vm._s(_vm.html) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }