import VueCookies from 'vue-cookies'
import requestWeb from '../../../utils/requestWeb'

const web = {
    namespaced: true,
    state:      {
        web_config:  {
            keyword: [],
        },
        cate:        [],
        cart_count:  0,
        cart_amount: 0,
    },
    mutations:  {

        setCartCount: (state, data) => {
            state.cart_count = data
        },

        setCartAmount: (state, data) => {
            state.cart_amount = data || 0
        },

        setWebConfig: (state, data) => {
            state.web_config = data
            state.cate       = data.cate
        },

        SET_I18N: (state, data) => {
            VueCookies.set('locale', data)
        },

        SET_WEB_TITLE: (state, name) => {
            document.title = state.web_config.webname ? (name + '-' + state.web_config.webname) : name
            setTimeout(() => {
                document.title = state.web_config.webname ? (name + '-' + state.web_config.webname) : name
            }, 200)
        },
    },

    actions: {

        cart({commit, state}) {

            requestWeb({
                url:    '/api/index/cart/list',
                method: 'post',
                data:   {},
            }).then(res => {

                commit('setCartAmount', res.data.money.good_amount)
                commit('setCartCount', res.data.count)
            })

        },

    },
}

export default web
