import enquireJs from 'enquire.js'
import dayjs from 'dayjs'

export function isDef(v) {
    return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove(arr, item) {
    if (arr.length) {
        const index = arr.indexOf(item)
        if (index > -1) {
            return arr.splice(index, 1)
        }
    }
}


export function isWeiXin() {
    let ua = window.navigator.userAgent.toLowerCase()

    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
    } else {
        return false
    }

}


export function getQueryParams(query) {
    let temp = {}
    let lets = query.split('&')
    for (let i = 0; i < lets.length; i++) {
        let pair      = lets[i].split('=')
        temp[pair[0]] = pair[1]
    }
    return temp
}


export function isRegExp(v) {
    return _toString.call(v) === '[object RegExp]'
}

export function enquireScreen(call) {
    const handler = {
        match:   function () {
            call && call(true)
        },
        unmatch: function () {
            call && call(false)
        },
    }
    enquireJs.register('only screen and (max-width: 767.99px)', handler)
}

const _toString = Object.prototype.toString


/**
 * 时间戳
 * @param dateString 2020-10-20 10:20
 * @returns {number}
 */
export function timeStampVue(dateString = undefined) {
    return dateString ? dayjs(dateString).unix() : dayjs().unix()
}

/**
 * 日期格式化
 * @param timeStamp
 * @param format 2:YYYY-MM-DD,3:YYYY-MM-DD HH:mm,4:YYYY/MM/DD HH:mm
 * @returns {string}
 */
export function dateVue(timeStamp = undefined, format = 'YYYY-MM-DD HH:mm:ss') {

    if (format === 2) {
        format = 'YYYY-MM-DD'
    }

    if (format === 3) {
        format = 'YYYY-MM-DD HH:mm'
    }
    if (format === 4) {
        format = 'YYYY/MM/DD HH:mm'
    }

    return timeStamp ? dayjs(parseInt(timeStamp) * 1000).format(format) : dayjs().format(format)
}

/**
 *
 * @param that
 * @param endTime
 * @param name
 */

export function countTimeVue(endTime) {

    let date = new Date()
    let now  = date.getTime()

    let leftTime = endTime * 1000 - now //时间差
    let d, h, m, s

    if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24) * 24
        h = Math.floor(leftTime / 1000 / 60 / 60 % 24)
        m = Math.floor(leftTime / 1000 / 60 % 60)
        s = Math.floor(leftTime / 1000 % 60)
        h = d + h
        s = s < 10 ? '0' + s : s
        m = m < 10 ? '0' + m : m
        h = h < 10 ? '0' + h : h

        return [h, m, s]
    } else {
        return ['00', '00', '00']
    }

}


export function formatMoney(web_config, value, prefix = false) {
    return web_config.money_symbol && value !== undefined ? (prefix ? web_config.money_prefix + ' ' : '') + web_config.money_symbol.replace('#', value) : '-'
}


export function copyVue(value) {
    let oInput   = document.createElement('input')
    oInput.value = value
    document.body.appendChild(oInput)
    oInput.select() // 选择对象
    document.execCommand('Copy') // 执行浏览器复制命令
    oInput.remove()

    Vue.prototype.$layer.msg('复制成功')
}

export function isMobile() {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}
