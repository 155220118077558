'use strict'
import 'babel-polyfill'
import index from './index.vue'
import store from './store/'
import router from './router'
import Vue from 'vue'
import layer from 'vue-layer'
import request from '../utils/requestWeb'
import myUploader from './components/myUploader'
import pagination from './components/pagination'


import createI18n from './i18n'
import './router/guard' // permission control
import '../../../assets/main/css/vue-layer.css'


let i18n = createI18n()


Vue.component('myUploader', myUploader)
Vue.component('pagination', pagination)
Vue.prototype.$layer     = layer(Vue)
Vue.config.productionTip = false
Vue.prototype.$request   = request


import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(Vant)

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)


import jquery from 'jquery'
window.jquery = window.$ = jquery

import VueTouch from "vue-touch";
Vue.use(VueTouch, {name: "v-touch"});

import VueLazyload from 'vue-lazyload'
import loading from '../../../assets/main/images/loading.png'
Vue.use(VueLazyload, {
    // preLoad: 1.3,
    error: '../../../assets/main/images/error.png',//图片错误的替换图片路径(可以使用变量存储)
    loading: loading,//正在加载的图片路径(可以使用变量存储)
    // attempt: 1
  })


store.commit('user/SET_TOKEN_USER', window.localStorage.getItem('Access-Token-User'))
store.dispatch('web/cart')


Vue.prototype.$lan = i18n
new Vue({
    router,
    store,
    i18n,
    // init localstorage, vuex
    render: h => h(index),
}).$mount('#app')

